export type File = {
  url: string
  formatted: {
    sm: string
    md: string
    lg: string
  }
  uid?: string
}

export type Activity = {
  _id?: string
  display_name: string
  name: string
  short_name: string
  tags: string[]
  files?: File[]
}

export type City = {
  files: File[]
  id: number
  name: string
}

export type Country = {
  continent?: string
  id?: number
  iso?: string
  name?: string
  nationality?: string
  prefix?: string | number
  code?: string
}

export type Currency = {
  code?: string
  id?: number
  name?: string
  symbol?: string
  description?: string
}

export type Phone = {
  merged: string
  number: string
  prefix: string
}

export type ContactPhone = {
  phone: Phone
  type: string
}

export type Coordinates = number[]

export type Location = {
  _id: string
  city: string
  coordinates?: Coordinates
  country: Country
  country_code?: string
  google_maps_url?: string
  name: string
  region: string
  min_price?: number
  total_vendors?: number
  currency_code?: string
  timezone?: string
}

export type ContactDetail = {
  contact_emails: string[]
  contact_phones: ContactPhone[]
  website: string
}

export type RentalOption = {
  currency: Currency
  duration?: number
  duration_unit?: string
  price: number
}

export type Vendor = {
  _id: string
  address: string
  can_rent: string[]
  contact_details: ContactDetail
  coordinates: Coordinates
  google_maps_url: string
  name: string
  vendor_name?: string
  vendor_location?: Location[]
  inventory_id?: string
  currency?: Currency[]
}

export type SnapShot = {
  _id: string
  activity: Activity
  location: Location
  payment_methods: string[]
  rental_options: RentalOption[]
  vendor: Vendor
}

export enum BookingStatus {
  Request = 'booking_requested',
  Completed = 'booking_completed',
  Confirmed = 'booking_confirmed',
  Pending = 'booking_pending',
  Rejected = 'booking_rejected',
  Cancelled = 'booking_cancelled',
}

export interface CancellationDetailsState {
  email: string
  phone: string
  status: boolean
}

export enum PaymentStatus {
  Unpaid = 'request_unpaid',
  Paid = 'request_paid',
}

export type BookingUser = {
  name?: string
  email?: string
  phone?: Phone
}

export interface ChangeRequest {
  booking_id?: string
  start_time?: string
  end_time?: string | undefined
  booking_status?: string
  inventory_id?: string | undefined
  location_id?: string | undefined
  snap_shot?: SnapShot | undefined
  price_record?: PriceRecord
}

export interface PriceRecord {
  created_at?: string
  old?: number
  new?: number
  new_in_booking_currency?: number
  applied?: number
  applied_in_booking_currency?: number
  status?: string
}

export type Booking = {
  booking_id: string
  booking_item_id: string
  booking_item_additional?: string | undefined
  booking_status: BookingStatus
  booking_user?: BookingUser
  currency?: Currency
  end_time: string
  locale: string
  payment_link: string
  payment_status: PaymentStatus
  profit_margin?: number
  sharable_id: string
  snap_shot?: SnapShot
  start_time: string
  change_request?: ChangeRequest
  vendor_price: {
    amount: number
    amount_in_booking_currency?: number
    currency: Currency
  }
  cancel_time?: string
  num_of_people?: number
  inventory_group_id?: string
  cancel_reason?: string
}

export type BookingService = {
  uuid: string
  name: string
  deletable: boolean
}

export type BookingEvent = {
  uuid: string
  name: string
  deletable: boolean
}

export type BookingItem = {
  uuid: string
  uuid_service: string
  uuid_booking: string
  currency: string
  price: number
  price_without_discount: number
  deletable: boolean
  status?: string
  refund_timestamp?: string
  refund_requested_timestamp?: string
  selected: boolean
  reference_code?: string
  internals?: string[]
  fees: BookingFee[]
}

export type BookingFee = {
  uuid: string
  name_private: string
  name_public: string
  amount: number
  refunded: boolean
}

export type BookingTransaction = {
  id: number
  uuid: string
  created_timestamp: string
  updated_timestamp: string
  deleted_timestamp?: string
  deleted: boolean
  user_payment_source: {
    id: number
    uuid: string
    created_timestamp: string
    updated_timestamp: string
    deleted_timestamp?: string
    deleted: boolean
    user: {
      id: number
      uuid: string
      created_timestamp: string
      updated_timestamp: string
      deleted_timestamp?: string
      deleted: boolean
      email?: string
    }
    payment_source: {
      ext_id: string
      card_number_last4: string
      fingerprint: string
      id: number
      uuid: string
      created_timestamp: string
      updated_timestamp: string
      deleted_timestamp?: string
      deleted: boolean
      payment_gateway: {
        id: number
        uuid: string
        created_timestamp: string
        updated_timestamp: string
        deleted_timestamp?: string
        deleted: boolean
        name: string
        status: string
      }
      payment_method: {
        id: number
        uuid: string
        created_timestamp: string
        updated_timestamp: string
        deleted_timestamp?: string
        deleted: boolean
        name: string
      }
    }
    default: boolean
  }
  ext_uuid_booking: string
  status: string
  amount: number
  refund_amount?: number
  currency: string
}

export type BookingPayment = {
  booking: {
    uuid: string
    currency: string
    fulfilled: boolean
    payment_paid_timestamp: string
    cancelled_timestamp?: string
    confirmed_timestamp?: string
    services: BookingService[]
    items: BookingItem[]
    fees: BookingFee[]
    events: BookingEvent[]
    discount: {
      amount_off: null
      percentage_off: null
    }
    total: number
    total_without_discount: number
    total_refunded: number
  }
  status: {
    payment: {
      transactions: BookingTransaction[]
    }
    watersports_activities: {
      requests: Booking[]
    }
  }
}

export type Pagination<T> = {
  items: T[]
  page: number
  page_size: number
  total_items: number
}

export type VendorItem = {
  _id: string
  id: number
  name: string
  vendor_name?: string
  vendor_location?: Location
  vendor_coordinates: Coordinates
  price: number
  inventory_id: string
  currency: Currency
  rental_options: RentalOption
  short_name?: string
  type: string | undefined
}

export type CurrencyResponse = Pagination<Currency>

export type SearchVendorResponse = {
  items: VendorItem[]
}

export type Inventory = {
  _id: string
  type: string
  payment_methods: string[]
  rental_options: RentalOption[]
}

export type InventoryGroup = {
  _id: string
  title: string
  type: string
  min_price: number
  reviews?: {
    count: number
    rating: number
  }
  min_duration?: {
    unit: 'minutes' | 'hours'
    value: number
    value_in_minus: number
  }
  inventories: Inventory[]
  coordinates: Coordinates
  files: File[]
}

export type InventoryGroupResponse = InventoryGroup
