import {
  Activity,
  Booking,
  BookingPayment,
  CancellationDetailsState,
  SearchVendorResponse,
  InventoryGroupResponse,
} from 'features/common/types'
import { bookingAPI, mainAPI, userAPI } from 'slices/api'

export const getBookingDetail = (bookingId: string): Promise<BookingPayment> =>
  bookingAPI.get(`/1.0/booking/${bookingId}/status`)

export const getBookingDetailFromSharableId = (shareId: string): Promise<Booking> =>
  mainAPI.get(`/booking/detail/${shareId}`)

export const confirmBooking = (payload: {
  bookingId: string
  pinCode: string
  cancel?: boolean
}): Promise<{ status: string }> =>
  mainAPI.post('/booking/agent-confirm', {
    booking_id: payload.bookingId,
    pin_code: payload.pinCode,
    cancel: payload.cancel,
  })

export const confirmChange = (payload: {
  pinCode: string
  requestStatus: string
  bookingId: string
}): Promise<{ status: string }> =>
  mainAPI.post(`/booking/${payload.bookingId}/agent/action`, {
    pin_code: payload.pinCode,
    request_status: payload.requestStatus,
  })

export const confirmProviderChange = (payload: {
  pinCode: string
  locationId?: string | undefined
  inventoryId?: string | undefined
  priceChange?: number | undefined
  oldPrice?: number | undefined
  bookingId: string
}): Promise<{ status: string }> =>
  mainAPI.post(`/booking/${payload.bookingId}/agent/change_provider`, {
    pin_code: payload.pinCode,
    location_id: payload.locationId,
    inventory_id: payload.inventoryId,
    price_change: payload.priceChange,
    old_price: payload.oldPrice,
  })

export const getActivityImg = (shortName: string): Promise<{ activities: Activity[] }> =>
  mainAPI.get('/activity', { params: { short_name: shortName } })

export const getVendors = (bookingId: string): Promise<SearchVendorResponse> => {
  const params = {
    booking_id: bookingId,
  }
  return mainAPI.get('/vendor/available', { params })
}

export const cancelBooking = (payload: {
  reason: string
  bookingId?: string
}): Promise<CancellationDetailsState> =>
  mainAPI.patch(`/booking/${payload.bookingId}/cancel`, payload)

export const changeBooking = (payload: {
  data: {
    new_start_time: string
    new_end_time: string
    request_status: string | undefined
  }
  booking_id: string
}): Promise<Response> => mainAPI.patch(`/booking/${payload.booking_id}/change_date`, payload.data)

export const sendBookingConfirmNotification = (
  booking_id: string,
  send_type: string,
): Promise<{ message: string }> =>
  userAPI.post('/webhook/booking-confirmation/user', { booking_id, send_type })

export const confirmPriceAndVendorChange = (payload: {
  requestStatus: string
  bookingId: string | undefined
}): Promise<{ status: string }> =>
  mainAPI.post(`/booking/${payload.bookingId}/user/action`, {
    request_status: payload.requestStatus,
  })

export const currencyConversion = (data: {
  base_currency_code: string
  desired_currency_code: string
}): Promise<{ conversion_rate: number }> => mainAPI.post('/currency', data)

export const getInventoryGroupDetails = (data: {
  inventoryGroupId: string
  currency: string
}): Promise<InventoryGroupResponse> => {
  const params = data.currency ? { currency: data.currency } : {}
  return mainAPI.get(`/inventory-group/${data.inventoryGroupId}`, { params })
}
