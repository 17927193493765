import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  BookingPayment,
  File,
  SearchVendorResponse,
  InventoryGroupResponse,
} from 'features/common/types'
import type { RootState } from 'store/store'

export interface BookingDetailState {
  loading: boolean
  booking: BookingPayment | undefined
  activityImg: File[]
  vendors: SearchVendorResponse
  error: unknown
  confirmed: boolean
  fetching: boolean
  inventoryGroupDetails: InventoryGroupResponse
}

const initialState: BookingDetailState = {
  loading: false,
  fetching: false,
  booking: undefined,
  error: undefined,
  confirmed: false,
  activityImg: [],
  vendors: {
    items: [],
  },
  inventoryGroupDetails: {} as InventoryGroupResponse,
}

const slice = createSlice({
  name: 'bookingDetail',
  initialState,
  reducers: {
    getBookingDetailStart(state) {
      state.loading = true
    },
    getBookingDetailSuccess(state, action: PayloadAction<BookingPayment>) {
      state.booking = action.payload
      state.loading = false
      state.error = undefined
    },
    getBookingDetailFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.booking = undefined
    },

    confirmBookingStart(state) {
      state.fetching = true
      state.confirmed = false
    },
    confirmBookingSuccess(state) {
      state.fetching = false
      state.error = undefined
      state.confirmed = true
    },
    confirmBookingFailure(state, action: PayloadAction<unknown>) {
      state.fetching = false
      state.error = action.payload
      state.confirmed = false
    },

    confirmChangeStart(state) {
      state.loading = true
    },
    confirmChangeSuccess(state) {
      state.loading = false
      state.error = undefined
    },
    confirmChangeFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    confirmPriceAndVendorChangeStart(state) {
      state.loading = true
    },
    confirmPriceAndVendorChangeSuccess(state) {
      state.loading = false
      state.error = undefined
    },
    confirmPriceAndVendorChangeFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    confirmProviderChangeStart(state) {
      state.loading = true
    },
    confirmProviderChangeSuccess(state) {
      state.loading = false
      state.error = undefined
    },
    confirmProviderChangeFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    getActivityImgStart(state) {
      state.loading = true
    },
    getActivityImgSuccess(state, action: PayloadAction<File[]>) {
      state.activityImg = action.payload
      state.loading = false
      state.error = undefined
    },
    getActivityImgFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.activityImg = []
    },

    getVendorsStart(state) {
      state.loading = true
    },
    getVendorsSuccess(state, action: PayloadAction<SearchVendorResponse>) {
      state.vendors.items = action.payload.items
      state.error = undefined
    },
    getVendorsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    getInventoryGroupDetailsStart(state) {
      state.loading = true
    },
    getInventoryGroupDetailsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
    getInventoryGroupDetailsSuccess(state, action: PayloadAction<InventoryGroupResponse>) {
      state.inventoryGroupDetails = action.payload
      state.loading = false
      state.error = undefined
    },
  },
})

export const bookingDetailActions = {
  getBookingDetail: createAction(`${slice.name}/getBookingDetail`, (bookingId: string) => ({
    payload: bookingId,
  })),
  getBookingDetailStart: slice.actions.getBookingDetailStart,
  getBookingDetailSuccess: slice.actions.getBookingDetailSuccess,
  getBookingDetailFailure: slice.actions.getBookingDetailFailure,

  confirmBooking: createAction(
    `${slice.name}/confirmBooking`,
    (payload: { bookingId: string; pinCode: string; cancel?: boolean }) => ({
      payload,
    }),
  ),
  confirmBookingStart: slice.actions.confirmBookingStart,
  confirmBookingSuccess: slice.actions.confirmBookingSuccess,
  confirmBookingFailure: slice.actions.confirmBookingFailure,

  confirmChange: createAction(
    `${slice.name}/confirmChange`,
    (payload: { requestStatus: string; pinCode: string; bookingId: string }) => ({
      payload,
    }),
  ),
  confirmChangeStart: slice.actions.confirmChangeStart,
  confirmChangeSuccess: slice.actions.confirmChangeSuccess,
  confirmChangeFailure: slice.actions.confirmChangeFailure,

  confirmPriceAndVendorChange: createAction(
    `${slice.name}/confirmPriceAndVendorChange`,
    (payload: { requestStatus: string; bookingId: string }) => ({
      payload,
    }),
  ),
  confirmPriceAndVendorChangeStart: slice.actions.confirmPriceAndVendorChangeStart,
  confirmPriceAndVendorChangeSuccess: slice.actions.confirmPriceAndVendorChangeSuccess,
  confirmPriceAndVendorChangeFailure: slice.actions.confirmPriceAndVendorChangeFailure,

  confirmProviderChange: createAction(
    `${slice.name}/confirmProviderChange`,
    (payload: {
      pinCode: string
      locationId?: string | undefined
      inventoryId?: string | undefined
      priceChange?: number | undefined
      oldPrice?: number | undefined
      bookingId: string
    }) => ({
      payload,
    }),
  ),
  confirmProviderChangeStart: slice.actions.confirmProviderChangeStart,
  confirmProviderChangeSuccess: slice.actions.confirmProviderChangeSuccess,
  confirmProviderChangeFailure: slice.actions.confirmProviderChangeFailure,

  getActivityImg: createAction(`${slice.name}/getActivityImg`, (shortName: string) => ({
    payload: shortName,
  })),
  getActivityImgStart: slice.actions.getActivityImgStart,
  getActivityImgSuccess: slice.actions.getActivityImgSuccess,
  getActivityImgFailure: slice.actions.getActivityImgFailure,

  getVendors: createAction(`${slice.name}/getVendors`, (payload: string) => ({
    payload,
  })),
  getVendorsStart: slice.actions.getVendorsStart,
  getVendorsSuccess: slice.actions.getVendorsSuccess,
  getVendorsFailure: slice.actions.getVendorsFailure,

  getInventoryGroupDetails: createAction(
    `${slice.name}/getInventoryGroupDetails`,
    (payload: { inventoryGroupId: string; currency: string }) => ({ payload }),
  ),
  getInventoryGroupDetailsStart: slice.actions.getInventoryGroupDetailsStart,
  getInventoryGroupDetailsSuccess: slice.actions.getInventoryGroupDetailsSuccess,
  getInventoryGroupDetailsFailure: slice.actions.getInventoryGroupDetailsFailure,
}

export const selectBookingDetail = (state: RootState) => state.bookingDetail

export default slice.reducer
