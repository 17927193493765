import { hotjar } from 'react-hotjar'

export default function setupHotjar(): void {
  const hjid = Number(process.env.REACT_APP_HOTJAR_SITE_ID)
  const hjVersion = 6

  if (hjid) {
    hotjar.initialize({ id: hjid, sv: hjVersion })
  } else {
    // eslint-disable-next-line no-console
    console.warn('Hotjar not initialized: Missing HOTJAR_SITE_ID')
  }
}
