export interface CancellationSuccessProps {
  email: string | undefined
  phone: string | undefined
  goToDetail: () => void
  goToHome: () => void
}

const CancellationSuccess = ({ email, phone, goToHome, goToDetail }: CancellationSuccessProps) => (
  <div className="flex justify-center items-center w-full h-screen">
    <div className="w-full m-2 pb-32">
      <div className="flex justify-center items-center w-full">
        <img src={'/static/images/Vector.svg'} alt="Success" />
      </div>
      <div className="flex justify-center items-center w-full">
        <h1 className="text-2xl py-5 font-semibold font-['Open_Sans']">Booking cancelled</h1>
      </div>
      <div className="flex justify-center items-center w-full">
        <p className="text-base font-normal text-neutral-800 font-['Roboto']">
          You successfully cancelled your booking. We will send you a confirmation to{' '}
          {(email || phone) && (
            <span className="text-cyan-600">
              {' '}
              {email}
              {email && phone && ' and '} {phone}{' '}
            </span>
          )}
          and issue a refund based on the cancellation policy.
        </p>
      </div>
      <p className="py-2 text-base font-normal font-['Roboto']">
        You don’t need to do anything else!
      </p>
      <div className="flex justify-center items-center w-full">
        <p
          className="py-2 text-base font-normal text-zinc-500 font-['Roboto']"
          onClick={goToDetail}
        >
          Booking cancellation details
        </p>
      </div>
      <div className="flex justify-center items-center w-full">
        <p className="py-2 text-base font-normal text-zinc-500 font-['Roboto']" onClick={goToHome}>
          Return to home screen
        </p>
      </div>
    </div>
  </div>
)

export default CancellationSuccess
