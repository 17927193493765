import { Button } from '@watersport/ui-react'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Header from 'features/common/components/Header'
import { Booking } from 'features/common/types'

export interface BookingDetails {
  booking: Booking | undefined
  symbol: string
  totalPaidPrice: number
  goBack: () => void
}

const ConfirmCancellation = ({ booking, totalPaidPrice, symbol, goBack }: BookingDetails) => {
  const navigate = useNavigate()
  const currentTime = moment()
  const startTime = moment(booking?.start_time)
  const duration = moment.duration(startTime?.diff(currentTime)).asHours()
  const cost = duration >= 24 ? 0 : totalPaidPrice

  return (
    <>
      <Header goBack={goBack} title="Cancel booking" />
      <div className="p-3 font-['Open_Sans'] font-normal">
        <p className="text-xl">
          {booking?.snap_shot?.activity?.display_name}
          {booking?.snap_shot?.location?.city}
        </p>
        <p className="py-3">You are about to cancel the entire booking.</p>
        <p>
          You may cancel free of charge and get a full refund until 24 hours before your booking
          date and time. You will be charged 100% of the total price of the booking if you cancel
          within the last 24 hours before your booking date and time.
        </p>

        <div className="pt-5 ">
          <div className="flex items-center mb-2.5">
            <div
              className={`${
                Number(cost) > 0
                  ? 'bg-orange-50 border-yellow-700'
                  : 'border-green-500 bg-emerald-100'
              }  border text-700 w-full px-4 py-3 rounded relative`}
              role="alert"
            >
              <p className="font-semibold pb-2">Cancellation cost</p>
              <p
                className={`font-medium font-['Roboto'] text-lg ${
                  Number(cost) > 0 ? 'text-orange-400' : 'text-green-500'
                }`}
              >
                {Number(cost) > 0
                  ? `Cancellation cost: ${symbol || '$'} ${Number(cost)}`
                  : 'Cancellation cost: free'}
              </p>
            </div>
          </div>
        </div>
        <Button
          type={'danger'}
          fontFamily={"font-['Open_Sans']"}
          label={'Cancel the booking'}
          weight={'font-medium'}
          onClick={() => navigate(`/${booking?.booking_id}/cancel`)}
          disabled={false}
        />
      </div>
    </>
  )
}

export default ConfirmCancellation
