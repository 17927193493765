import { Button, Loading, ReasonRadio } from '@watersport/ui-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { REASON_FOR_CANCEL, SEARCH_URL } from 'constant/index'
import { CancellationSuccess } from 'features/bookingCancel'
import Header from 'features/common/components/Header'
import { useBookingDetail } from 'features/common/hooks/useBookingDetail'
import { BookingStatus } from 'features/common/types'
import { cancelBooking } from 'slices/bookingDetail/api'

const ReasonList = () => {
  const navigate = useNavigate()
  const [reason, setReason] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const {
    booking,
    loading: load,
    getBookingDetail,
    email,
    phoneNumber,
    error: bookingError,
  } = useBookingDetail()
  const [error, setError] = useState<unknown>('')

  const handleCancelBooking = useCallback(async () => {
    if (!booking?.booking_id) return
    try {
      setLoading(true)
      await cancelBooking({ bookingId: booking?.booking_id, reason })
      await getBookingDetail(booking?.booking_id)
    } catch (err) {
      setError(err as unknown)
    } finally {
      setLoading(false)
    }
  }, [booking?.booking_id, getBookingDetail, reason])

  useEffect(() => {
    if (bookingError) setError(bookingError)
  }, [bookingError])

  const goToHome = useCallback(() => {
    window.location.href = SEARCH_URL
  }, [])

  const goToDetail = useCallback(() => {
    navigate(`/${booking?.booking_id}/details`)
  }, [booking?.booking_id, navigate])

  return (
    <>
      {load && <Loading>{'Please wait...'}</Loading>}
      {booking?.booking_status === BookingStatus.Cancelled ? (
        <CancellationSuccess
          email={email}
          phone={phoneNumber}
          goToHome={goToHome}
          goToDetail={goToDetail}
        />
      ) : (
        <div>
          <Header goBack={() => navigate(-1)} title="Cancel booking" />
          <div className="p-3 font-['Open_Sans'] font-normal">
            <h1 className="text-2xl">Reason of cancellation</h1>
            <h2 className="text-base pt-3 font-normal text-zinc-600">
              Tell us your reason for cancelling your booking
            </h2>
            <div className="w-full pt-3">
              <div role="listbox">
                {REASON_FOR_CANCEL.map((reasonText, index) => (
                  <ReasonRadio
                    key={index}
                    index={index}
                    onClick={() => setReason(reasonText)}
                    text={reasonText}
                  />
                ))}
              </div>
              <>
                {error && (
                  <div className="text-red-400 pb-2" role="mark">
                    Something went wrong. Please, try again after some time.
                  </div>
                )}
              </>
              <div className="pl-1 fixed bottom-3 w-full">
                <Button
                  loading={loading}
                  width={'w-11/12'}
                  fontFamily={"font-['Open_Sans']"}
                  label={'Cancel booking'}
                  weight={'font-medium'}
                  onClick={handleCancelBooking}
                  disabled={!reason}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReasonList
