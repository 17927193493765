interface Config {
  [key: string]: {
    apiUrl: string
    bookingUrl: string
    userApiUrl: string
    verifyUrl: string
    searchUrl: string
    baseUrl: string
    debug?: boolean
    gtmId?: string
    metaPixelId?: string
    showFeature?: boolean
  }
}

const CONFIG_MAP: Config = {
  development: {
    apiUrl: 'https://api.development.w-sports.click',
    bookingUrl: 'https://book.development.w-sports.click',
    userApiUrl: 'https://user-api.development.w-sports.click',
    verifyUrl: 'https://verify.development.w-sports.click',
    searchUrl: 'https://search.development.w-sports.click',
    baseUrl: 'https://development.w-sports.click',
    gtmId: 'GTM-KRQBNC7',
    metaPixelId: '1248374336612608',
    showFeature: true,
  },

  staging: {
    apiUrl: 'https://api.test.w-sports.click',
    bookingUrl: 'https://book.test.w-sports.click',
    userApiUrl: 'https://user-api.test.w-sports.click',
    verifyUrl: 'https://verify.test.w-sports.click',
    searchUrl: 'https://search.test.w-sports.click',
    baseUrl: 'https://test.w-sports.click',
    metaPixelId: '1248374336612608',
    gtmId: 'GTM-KMB4PJN',
  },

  production: {
    apiUrl: 'https://api.w-sports.click',
    bookingUrl: 'https://book.w-sports.click',
    userApiUrl: 'https://user-api.w-sports.click',
    verifyUrl: 'https://verify.w-sports.click',
    searchUrl: 'https://search.w-sports.click',
    baseUrl: 'https://w-sports.click',
    metaPixelId: '1248374336612608',
    gtmId: 'GTM-NQRCMSM',
  },
}

CONFIG_MAP.local = {
  ...CONFIG_MAP.development,
  debug: true,
}

const env = process.env.REACT_APP_ENV || 'development'

const CONFIG = CONFIG_MAP[env] || CONFIG_MAP.development

export default CONFIG
