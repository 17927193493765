import { Loadable } from '@watersport/ui-react'
import { lazy } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import BookingCancelPage from 'pages/BookingCancelPage'

import './index.css'

const BookingCallUsPage = Loadable(lazy(() => import('pages/BookingCallUsPage')))
const BookingConfirmedPage = Loadable(lazy(() => import('pages/BookingConfirmedPage')))
const BookingDetailPage = Loadable(lazy(() => import('pages/BookingDetailPage')))
const BookingRedirectPage = Loadable(lazy(() => import('pages/BookingRedirectPage')))
const BookingSupportPage = Loadable(lazy(() => import('pages/BookingSupport')))
const NotFound = Loadable(lazy(() => import('pages/NotFound')))

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/404" element={<NotFound />} />
      <Route path="/:bookingId/confirmed" element={<BookingConfirmedPage />} />
      <Route path="/:bookingId/details" element={<BookingDetailPage isAgent={false} />} />
      <Route path="/:bookingId/details/user-agent" element={<BookingDetailPage isAgent={true} />} />
      <Route path="/:bookingId/cancel" element={<BookingCancelPage />} />
      <Route path="/detail/:shareId" element={<BookingRedirectPage />} />
      <Route path="/:bookingId/support" element={<BookingSupportPage />} />
      <Route path="/:bookingId/feedback" element={<BookingSupportPage />} />
      <Route path="/:bookingId/call-us" element={<BookingCallUsPage />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  </BrowserRouter>
)

export default App
