import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Vendor } from 'features/common/types'
import type { RootState } from 'store/store'

export interface BookSummaryState {
  loading: boolean
  changeProvider: string | undefined
  updatedVendor: Vendor | undefined
  newPrice: number | undefined
  showCalendar: boolean
  showChangePage: boolean
  showRequestChange: boolean
  date: string | undefined
  time: string | undefined
  step: number
  error: unknown
}

const initialState: BookSummaryState = {
  loading: false,
  changeProvider: undefined,
  updatedVendor: undefined,
  newPrice: undefined,
  showCalendar: false,
  showChangePage: false,
  showRequestChange: false,
  date: undefined,
  time: undefined,
  step: 1,
  error: undefined,
}

const slice = createSlice({
  name: 'bookSummary',
  initialState,
  reducers: {
    setChangeProvider(state, action) {
      state.changeProvider = action.payload
    },
    setUpdatedVendor(state, action) {
      state.updatedVendor = action.payload
    },
    setNewPrice(state, action) {
      state.newPrice = action.payload
    },
    setShowCalendar(state, action) {
      state.showCalendar = action.payload
    },
    setShowChangePage(state, action) {
      state.showChangePage = action.payload
    },
    setDate(state, action) {
      state.date = action.payload
    },
    setTime(state, action) {
      state.time = action.payload
    },
    setStep(state, action) {
      state.step = action.payload
    },
    setShowRequestChange(state, action) {
      state.showRequestChange = action.payload
    },

    getVendorDetailStart(state) {
      state.loading = true
    },
    getVendorDetailSuccess(state, action: PayloadAction<Vendor>) {
      state.updatedVendor = action.payload
      state.error = undefined
    },
    getVendorDetailFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const bookSummaryActions = {
  setChangeProvider: slice.actions.setChangeProvider,
  setUpdatedVendor: slice.actions.setUpdatedVendor,
  setNewPrice: slice.actions.setNewPrice,
  setShowCalendar: slice.actions.setShowCalendar,
  setShowChangePage: slice.actions.setShowChangePage,
  setShowRequestChange: slice.actions.setShowRequestChange,
  setDate: slice.actions.setDate,
  setTime: slice.actions.setTime,
  setStep: slice.actions.setStep,

  getVendorDetail: createAction(`${slice.name}/getVendorDetail`, (payload: string) => ({
    payload,
  })),
  getVendorDetailStart: slice.actions.getVendorDetailStart,
  getVendorDetailSuccess: slice.actions.getVendorDetailSuccess,
  getVendorDetailFailure: slice.actions.getVendorDetailFailure,
}

export const selectBookSummary = (state: RootState) => state.bookingSummary

export default slice.reducer
