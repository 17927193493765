import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { Vendor } from 'features/common/types'
import { getVendorDetail } from 'slices/bookSummary/api'
import { bookSummaryActions } from 'slices/bookSummary/store/bookSummary.slice'

function* onGetVendorDetail({
  payload,
}: {
  type: typeof bookSummaryActions.getVendorDetail
  payload: string
}): SagaIterator {
  yield put(bookSummaryActions.getVendorDetailStart())
  try {
    const res: Vendor = yield call(getVendorDetail, payload)
    yield put(bookSummaryActions.getVendorDetailSuccess(res))
  } catch (e) {
    yield put(bookSummaryActions.getVendorDetailFailure(e))
  }
}

// Watcher Saga
export function* bookSummaryWatcherSaga(): SagaIterator {
  yield takeEvery(bookSummaryActions.getVendorDetail.type, onGetVendorDetail)
}

export default bookSummaryWatcherSaga
