import React from 'react'

export interface SubHeaderProps {
  logo?: boolean
  goBack?: () => void
  title?: string | JSX.Element
  clickSupport?: () => void
}

const Header = ({ goBack, title, logo, clickSupport }: SubHeaderProps) => (
  <div className="flex items-center justify-left inset-x-0 top-0 bg-white p-2.5 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] text-2xl font-light font-['Open_Sans']">
    {goBack && (
      <img
        onClick={goBack}
        className="h-7 pr-2"
        src={'/static/images/arrow_back_ios.svg'}
        alt="imgLogo"
      />
    )}
    {logo && (
      <img
        src={'/static/images/header__waves-logo.svg'}
        loading="lazy"
        alt="imgBack"
        className="w-8 h-8"
      />
    )}
    {title ? (
      <div className="ml-2.5 grow">{title}</div>
    ) : (
      <div className="italic font-semibold ml-2.5 grow">{'WATERSPORTS'}</div>
    )}
    {clickSupport && (
      <img
        onClick={clickSupport}
        className="h-5 pl-2"
        src={'/static/images/support-black.svg'}
        alt="imgSupport"
      />
    )}
  </div>
)

export default Header
