import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  BOOKING_URL,
  CountryListsWithPhoneCode,
  VERIFY_URL,
  ZERO_DECIMAL_CURRENCIES,
} from 'constant'
import { BookingStatus } from 'features/common/types'
import { useBookingDetailService } from 'slices/bookingDetail/hooks'

export const useBookingDetail = () => {
  const { bookingId } = useParams()
  const {
    bookingDetail: { booking: bookingPayment, loading, error },
    getBookingDetail,
  } = useBookingDetailService()

  useEffect(() => {
    if (bookingId) {
      getBookingDetail(bookingId)
    }
  }, [bookingId, getBookingDetail])

  const booking = useMemo(() => {
    if ((bookingPayment?.status.watersports_activities?.requests?.length || 0) > 0) {
      return bookingPayment?.status.watersports_activities.requests[0]
    }
    return undefined
  }, [bookingPayment?.status.watersports_activities.requests])

  const vendor = useMemo(() => booking?.snap_shot?.vendor, [booking?.snap_shot?.vendor])

  const rentalOption = useMemo(
    () =>
      (booking?.snap_shot?.rental_options.length || 0) > 0
        ? booking?.snap_shot?.rental_options[0]
        : undefined,
    [booking?.snap_shot?.rental_options],
  )

  const location = useMemo(() => booking?.snap_shot?.location, [booking?.snap_shot?.location])

  const changeRequest = useMemo(() => booking?.change_request, [booking?.change_request])

  const firstRequestPrice = useMemo(() => {
    if (bookingPayment && bookingPayment?.booking.items.length > 0) {
      let items = bookingPayment?.booking.items
      if (booking?.booking_item_additional) {
        items = items.slice(0, -1)
      }
      return items.reduce((acc, curr) => acc + curr.price, 0)
    }
    return 0
  }, [booking?.booking_item_additional, bookingPayment])

  const secondRequestPrice = useMemo(() => {
    if (booking?.change_request?.price_record?.applied_in_booking_currency)
      return booking?.change_request?.price_record?.applied_in_booking_currency

    if (
      bookingPayment &&
      booking?.booking_item_additional &&
      bookingPayment?.booking.items.length > 1
    )
      return bookingPayment?.booking.items.at(-1)?.price ?? 0
    return 0
  }, [
    booking?.booking_item_additional,
    booking?.change_request?.price_record?.applied_in_booking_currency,
    bookingPayment,
  ])

  const vendorPrice = useMemo(
    () => Number(booking?.vendor_price.amount.toFixed(2) || 0),
    [booking?.vendor_price],
  )

  const vendorPriceWithBookingCurrency = useMemo(
    () => Number(booking?.vendor_price.amount_in_booking_currency?.toFixed(2) || 0),
    [booking?.vendor_price],
  )

  const vendorCurrencyCode = useMemo(
    () => booking?.vendor_price.currency.code,
    [booking?.vendor_price],
  )

  const updatedVendorPrice = useMemo(
    () =>
      booking?.change_request?.price_record?.new_in_booking_currency ||
      vendorPriceWithBookingCurrency,
    [
      booking?.change_request?.price_record?.new_in_booking_currency,
      vendorPriceWithBookingCurrency,
    ],
  )

  const totalPaidPrice = useMemo(() => {
    const transactions = (bookingPayment?.status?.payment?.transactions ?? []).filter(
      t => t.status === 'succeeded',
    )

    if (
      transactions.length === 1 &&
      booking?.change_request?.price_record?.applied_in_booking_currency
    ) {
      let firstPaid
      if (
        transactions[0].user_payment_source.payment_source.payment_gateway.name === 'stripe' &&
        !ZERO_DECIMAL_CURRENCIES.includes(transactions[0].currency.toLocaleUpperCase())
      ) {
        firstPaid = transactions[0].amount / 100
      } else {
        firstPaid = transactions[0].amount
      }
      return firstPaid + (booking?.change_request?.price_record?.applied_in_booking_currency || 0)
    }

    return transactions.reduce((acc, curr) => {
      if (
        curr.user_payment_source.payment_source.payment_gateway.name === 'stripe' &&
        !ZERO_DECIMAL_CURRENCIES.includes(transactions[0].currency.toLocaleUpperCase())
      ) {
        return acc + curr.amount / 100
      }
      return acc + curr.amount
    }, 0)
  }, [
    booking?.change_request?.price_record?.applied_in_booking_currency,
    bookingPayment?.status?.payment?.transactions,
  ])

  const transactions = useMemo(
    () =>
      bookingPayment?.status?.payment?.transactions
        .filter(t => t.status === 'succeeded')
        .map(t => ({
          ...t,
          amount:
            t.user_payment_source.payment_source.payment_gateway.name === 'stripe'
              ? t.amount / 100
              : t.amount,
        })),
    [bookingPayment?.status?.payment?.transactions],
  )

  const symbol = useMemo(() => booking?.currency?.symbol || '$', [booking?.currency?.symbol])

  const currencyCode = useMemo(() => booking?.currency?.code || '$', [booking?.currency?.code])

  const isBookingConfirmed = useMemo(
    () => booking?.booking_status === BookingStatus.Confirmed,
    [booking],
  )

  const transaction = useMemo(() => {
    if ((bookingPayment?.status?.payment?.transactions?.length || 0) > 0) {
      return bookingPayment?.status?.payment?.transactions.find(t => t.status === 'succeeded')
    }
    return undefined
  }, [bookingPayment?.status.payment.transactions])

  const phoneNumber = useMemo(() => booking?.booking_user?.phone?.number, [booking])
  const email = useMemo(() => booking?.booking_user?.email, [booking])
  const country = useMemo(() => {
    const index = CountryListsWithPhoneCode.findIndex(
      c => c.prefix === booking?.booking_user?.phone?.prefix,
    )
    if (index !== -1) return CountryListsWithPhoneCode[index]
    return undefined
  }, [booking])
  const emailVerify = useMemo(
    () => booking?.booking_user?.email || false,
    [booking?.booking_user?.email],
  )
  const phoneVerify = useMemo(
    () => booking?.booking_user?.phone || false,
    [booking?.booking_user?.phone],
  )

  const numberOfPeople = useMemo(() => booking?.num_of_people, [booking?.num_of_people])

  useEffect(() => {
    if (!booking) return

    if (transaction) return

    if (emailVerify || phoneVerify) {
      const successUrl = window.location.href
      const paymentLink = `${BOOKING_URL}/booking/redirect/${booking.booking_id}/?locale=${booking.locale}&currency=${booking.currency?.code}`
      const cancelLink = `${VERIFY_URL}/${booking.booking_id}/contact-info`
      window.location.href = `${paymentLink}&success_url=${successUrl}&cancel_url=${cancelLink}${
        booking.payment_link.includes('app_version=v2') ? '&app_version=v2' : ''
      }`
    } else {
      window.location.href = `${VERIFY_URL}/${booking.booking_id}/contact-info`
    }
  }, [booking, emailVerify, phoneVerify, transaction])

  return {
    error,
    loading,
    booking,
    getBookingDetail,
    transaction,
    firstRequestPrice,
    secondRequestPrice,
    vendorPrice,
    vendorPriceWithBookingCurrency,
    vendorCurrencyCode,
    updatedVendorPrice,
    totalPaidPrice,
    symbol,
    currencyCode,
    isBookingConfirmed,
    phoneNumber,
    email,
    country,
    emailVerify,
    phoneVerify,
    vendor,
    location,
    changeRequest,
    transactions,
    rentalOption,
    numberOfPeople,
  }
}
