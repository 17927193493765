import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  Activity,
  BookingPayment,
  SearchVendorResponse,
  InventoryGroupResponse,
} from 'features/common/types'
import {
  confirmBooking,
  getBookingDetail,
  getActivityImg,
  confirmChange,
  confirmPriceAndVendorChange,
  confirmProviderChange,
  getVendors,
  getInventoryGroupDetails,
} from 'slices/bookingDetail/api'
import { bookingDetailActions } from 'slices/bookingDetail/store/bookingDetail.slice'

function* onGetBookingDetail({
  payload,
}: {
  type: typeof bookingDetailActions.getBookingDetail
  payload: string
}): SagaIterator {
  yield put(bookingDetailActions.getBookingDetailStart())
  try {
    const res: BookingPayment = yield call(getBookingDetail, payload)
    yield put(bookingDetailActions.getBookingDetailSuccess(res))
  } catch (e) {
    yield put(bookingDetailActions.getBookingDetailFailure(e))
  }
}

function* onConfirmBooking({
  payload,
}: {
  type: typeof bookingDetailActions.confirmBooking
  payload: { bookingId: string; pinCode: string; cancel?: boolean }
}): SagaIterator {
  yield put(bookingDetailActions.confirmBookingStart())
  try {
    yield call(confirmBooking, payload)
    yield put(bookingDetailActions.confirmBookingSuccess())
    yield put(bookingDetailActions.getBookingDetail(payload.bookingId))
  } catch (e) {
    yield put(bookingDetailActions.confirmBookingFailure(e))
  }
}

function* onConfirmChange({
  payload,
}: {
  type: typeof bookingDetailActions.confirmChange
  payload: { requestStatus: string; pinCode: string; bookingId: string }
}): SagaIterator {
  yield put(bookingDetailActions.confirmChangeStart())
  try {
    yield call(confirmChange, payload)
    yield put(bookingDetailActions.confirmChangeSuccess())
    yield put(bookingDetailActions.getBookingDetail(payload.bookingId))
  } catch (e) {
    yield put(bookingDetailActions.confirmChangeFailure(e))
  }
}

function* onConfirmPriceAndVendorChange({
  payload,
}: {
  type: typeof bookingDetailActions.confirmPriceAndVendorChange
  payload: { requestStatus: string; bookingId: string }
}): SagaIterator {
  yield put(bookingDetailActions.confirmPriceAndVendorChangeStart())
  try {
    yield call(confirmPriceAndVendorChange, payload)
    yield put(bookingDetailActions.confirmPriceAndVendorChangeSuccess())
    yield put(bookingDetailActions.getBookingDetail(payload.bookingId))
  } catch (e) {
    yield put(bookingDetailActions.confirmPriceAndVendorChangeFailure(e))
  }
}

function* onConfirmProviderChange({
  payload,
}: {
  type: typeof bookingDetailActions.confirmProviderChange
  payload: {
    pinCode: string
    locationId?: string | undefined
    inventoryId?: string | undefined
    priceChange?: number | undefined
    oldPrice?: number | undefined
    bookingId: string
  }
}): SagaIterator {
  yield put(bookingDetailActions.confirmProviderChangeStart())
  try {
    yield call(confirmProviderChange, payload)
    yield put(bookingDetailActions.confirmProviderChangeSuccess())
    yield put(bookingDetailActions.getBookingDetail(payload.bookingId))
  } catch (e) {
    yield put(bookingDetailActions.confirmProviderChangeFailure(e))
  }
}

function* onGetActivityImg({
  payload,
}: {
  type: typeof bookingDetailActions.getActivityImg
  payload: string
}): SagaIterator {
  yield put(bookingDetailActions.getActivityImgStart())
  try {
    const res: { items: Activity[] } = yield call(getActivityImg, payload)
    const files = res.items[0].files || []
    if (files) {
      yield put(bookingDetailActions.getActivityImgSuccess(files))
    } else {
      yield put(bookingDetailActions.getActivityImgFailure('No activity file fetched.'))
    }
  } catch (e) {
    yield put(bookingDetailActions.getActivityImgFailure(e))
  }
}

function* onGetVendors({
  payload,
}: {
  type: typeof bookingDetailActions.getVendors
  payload: string
}): SagaIterator {
  try {
    const result: SearchVendorResponse = yield call(getVendors, payload)
    yield put(bookingDetailActions.getVendorsSuccess(result))
  } catch (e) {
    yield put(bookingDetailActions.getVendorsFailure(e))
  }
}

function* onGetInventoryGroupDetails({
  payload,
}: {
  type: typeof bookingDetailActions.getInventoryGroupDetails
  payload: { inventoryGroupId: string; currency: string }
}): SagaIterator {
  yield put(bookingDetailActions.getInventoryGroupDetailsStart())
  try {
    const res: InventoryGroupResponse = yield call(getInventoryGroupDetails, payload)
    yield put(bookingDetailActions.getInventoryGroupDetailsSuccess(res))
  } catch (e) {
    yield put(bookingDetailActions.getInventoryGroupDetailsFailure(e))
  }
}

// Watcher Saga
export function* bookingDetailWatcherSaga(): SagaIterator {
  yield takeEvery(bookingDetailActions.getBookingDetail.type, onGetBookingDetail)
  yield takeEvery(bookingDetailActions.confirmBooking.type, onConfirmBooking)
  yield takeEvery(
    bookingDetailActions.confirmPriceAndVendorChange.type,
    onConfirmPriceAndVendorChange,
  )
  yield takeEvery(bookingDetailActions.confirmProviderChange.type, onConfirmProviderChange)
  yield takeEvery(bookingDetailActions.confirmChange.type, onConfirmChange)
  yield takeEvery(bookingDetailActions.getActivityImg.type, onGetActivityImg)
  yield takeEvery(bookingDetailActions.getVendors.type, onGetVendors)
  yield takeEvery(bookingDetailActions.getInventoryGroupDetails.type, onGetInventoryGroupDetails)
}

export default bookingDetailWatcherSaga
