import { all, fork } from 'redux-saga/effects'

import { bookingDetailWatcherSaga } from 'slices/bookingDetail/store'
import { bookSummaryWatcherSaga } from 'slices/bookSummary/store'
import { currencyWatcherSaga } from 'slices/currency/store'

export function* rootSaga() {
  yield all([fork(bookingDetailWatcherSaga)])
  yield all([fork(bookSummaryWatcherSaga)])
  yield all([fork(currencyWatcherSaga)])
}

export default rootSaga
