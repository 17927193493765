import { useCallback } from 'react'

import {
  bookingDetailActions,
  BookingDetailState,
  selectBookingDetail,
} from 'slices/bookingDetail/store'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type BookingDetailOperators = {
  bookingDetail: BookingDetailState
  getBookingDetail: (bookingId: string) => void
  confirmBooking: (data: { bookingId: string; pinCode: string; cancel?: boolean }) => void
  updateRequestStatusByCustomer: (data: {
    requestStatus: string
    pinCode: string
    bookingId: string
  }) => void
  updateRequestStatusByUser: (data: { requestStatus: string; bookingId: string }) => void
  updateProviderByCustomer: (data: {
    pinCode: string
    locationId?: string | undefined
    inventoryId?: string | undefined
    priceChange?: number | undefined
    oldPrice?: number | undefined
    bookingId: string
  }) => void
  getActivityImg: (shortName: string) => void
  getVendors: (bookingId: string) => void
  getInventoryGroupDetails: (data: { inventoryGroupId: string; currency: string }) => void
}

export const useBookingDetailService = (): Readonly<BookingDetailOperators> => {
  const dispatch = useAppDispatch()

  return {
    bookingDetail: useAppSelector(selectBookingDetail),
    getBookingDetail: useCallback(
      (bookingId: string) => dispatch(bookingDetailActions.getBookingDetail(bookingId)),
      [dispatch],
    ),
    confirmBooking: useCallback(
      (data: { bookingId: string; pinCode: string; cancel?: boolean }) =>
        dispatch(bookingDetailActions.confirmBooking(data)),
      [dispatch],
    ),
    updateRequestStatusByCustomer: useCallback(
      (data: { requestStatus: string; pinCode: string; bookingId: string }) =>
        dispatch(bookingDetailActions.confirmChange(data)),
      [dispatch],
    ),
    updateRequestStatusByUser: useCallback(
      (data: { requestStatus: string; bookingId: string }) =>
        dispatch(bookingDetailActions.confirmPriceAndVendorChange(data)),
      [dispatch],
    ),
    updateProviderByCustomer: useCallback(
      (data: {
        pinCode: string
        locationId?: string | undefined
        inventoryId?: string | undefined
        priceChange?: number | undefined
        oldPrice?: number | undefined
        bookingId: string
      }) => dispatch(bookingDetailActions.confirmProviderChange(data)),
      [dispatch],
    ),
    getActivityImg: useCallback(
      (shortName: string) => dispatch(bookingDetailActions.getActivityImg(shortName)),
      [dispatch],
    ),
    getVendors: useCallback(
      (bookingId: string) => dispatch(bookingDetailActions.getVendors(bookingId)),
      [dispatch],
    ),
    getInventoryGroupDetails: useCallback(
      (data: { inventoryGroupId: string; currency: string }) =>
        dispatch(bookingDetailActions.getInventoryGroupDetails(data)),
      [dispatch],
    ),
  }
}

export default useBookingDetailService
