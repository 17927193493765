import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Currency } from 'features/common/types'
import type { RootState } from 'store/store'

export interface CurrencyState {
  loading: boolean
  currencies: Currency[]
  error: unknown
  defaultCurrencyString: string | undefined
  selectedCurrency: Currency | undefined
}

const initialState: CurrencyState = {
  loading: false,
  currencies: [],
  error: undefined,
  defaultCurrencyString: undefined,
  selectedCurrency: undefined,
}

const slice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    getCurrenciesStart(state) {
      state.loading = true
    },
    getCurrenciesSuccess(state, action: PayloadAction<Currency[]>) {
      state.currencies = action.payload
      state.loading = false
      state.error = undefined
    },
    getCurrenciesFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.currencies = []
    },
    setSelectedCurrency(state, action: PayloadAction<Currency | undefined>) {
      state.selectedCurrency = action.payload
    },
  },
})

export const currencyActions = {
  getCurrencies: createAction(`${slice.name}/getCurrencies`, (location?: string) => ({
    payload: location,
  })),
  getCurrenciesStart: slice.actions.getCurrenciesStart,
  getCurrenciesSuccess: slice.actions.getCurrenciesSuccess,
  getCurrenciesFailure: slice.actions.getCurrenciesFailure,
  setSelectedCurrency: slice.actions.setSelectedCurrency,
}

export const selectCurrency = (state: RootState) => state.currency

export default slice.reducer
